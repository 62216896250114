/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  message,
  Statistic,
  Card,
} from 'antd';
import {
  collection, addDoc, getDocsFromServer,
} from 'firebase/firestore';
import { FirebaseUtils } from '../../firebase-utils';

const { Countdown } = Statistic;

const deadline = 1699230286751; // Dayjs is also OK

export function SubscriberForm() {
  const [records, setRecords] = useState(0);
  const [form] = Form.useForm();

  const values = Form.useWatch([], form);

  const updateRecords = useCallback(() => {
    if (FirebaseUtils.db) {
      const coll = collection(FirebaseUtils.db, 'subscribers');
      getDocsFromServer(coll).then((snapshot: any) => {
        setRecords(snapshot.docs.length);
      });
    }
  }, []);

  useEffect(() => {
    updateRecords();
  }, [updateRecords, FirebaseUtils.db]);

  const addSubscriber = async () => {
    try {
      await addDoc(collection(FirebaseUtils.db, 'subscribers'), {
        socialAccount: values.socialAccount,
        youtubeAccount: values.youtubeAccount,
      });

      message.success('You have been successfully registered!');
      updateRecords();
    } catch (e: any) {
      message.error('Something went wrong, please try again!');
    }
  };

  const onFinish = () => {
    if (!values.socialAccount?.length) {
      message.warn('Please provide your social account URL or ID');
      return;
    }
    if (!values.youtubeAccount?.length) {
      message.warn('Please provide your YouTube account URL or ID');
    }

    addSubscriber();
  };

  return (
    <div>
      <Card style={{ borderColor: 'red' }}>
        <Countdown style={{ color: 'red' }} title="Ending in" value={deadline} onFinish={onFinish} format="HH:mm:ss:SSS" />
      </Card>

      <Card style={{ marginTop: 10 }}>
        <div>
          <p><b>Before registering make sure:</b></p>
          <p>
            1) Subscribe
            {' '}
            <a href="https://www.youtube.com/@gameino" target="_blank" rel="noreferrer">GAMEINO youtube channel</a>
          </p>
          <p>2) Make sure you have provided your youtube account</p>
          <p>3) Make sure you have provided your social account</p>
          <p>4) 5 subscribers will 20$</p>
        </div>
        <Form
          layout="vertical"
          form={form}
          style={{ minWidth: 400, maxWidth: 500 }}
          onFinish={onFinish}
        >
          <Form.Item name="youtubeAccount" label="Your Youtube account url">
            <Input placeholder="Youtube account URL" />
          </Form.Item>
          <Form.Item name="socialAccount" label="Your instagram / tiktok account">
            <Input placeholder="Account URL / ID" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Register</Button>
          </Form.Item>
        </Form>
      </Card>

      <Card style={{ marginTop: 10, borderColor: 'green' }}>
        <Statistic title="Registered users" value={`${records} / 500`} />
      </Card>
    </div>
  );
}
