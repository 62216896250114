import React from 'react';
import { Card } from 'antd';

const { Meta } = Card;

function VideoCard({
  title,
  description,
  iframe,
}: any) {
  const openVideoLink = () => {
    const videoLink = iframe.props.src.replace('embed/', 'watch?v=');
    window.open(videoLink, '_blank');
  };

  return (
    <Card
      hoverable
      style={{
        width: 360,
        height: 280,
        margin: 15,
        boxShadow: '0px 0px 20px rgb(0 0 0 / 0.3)',
      }}
      cover={iframe}
      onClick={openVideoLink}
    >
      <Meta
        title={title}
        description={description}
      />
    </Card>
  );
}

export { VideoCard };
