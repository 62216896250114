export * from './colors';

export const gameinoChannelLink = 'https://www.youtube.com/channel/UCBfEgoyWUPqoF6LG788p19g/featured';

export const breakpoints = {
  extraSmall: '576px',
  small: '768px',
  medium: '992px',
  large: '1200px',
};
