import React, { useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { FirebaseUtils } from './firebase-utils';
import { Routes } from './routes';

function App() {
  useEffect(() => {
    FirebaseUtils.initialize();
  }, []);

  return (
    <>
      <Routes />
      <TawkMessengerReact
        propertyId="633c9ff737898912e96ce4e6"
        widgetId="1geid1npe"
      />
    </>
  );
}

export default App;
