import React from 'react';

export const videos = [
  {
    title: 'Euro Truck Simulator 2 (ETS 2)',
    description: 'Truck Simulator | 4k Ultra-Realistic graphics Sunset Drive',
    iframe: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/TBCvXR94nDE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />,
  },
  {
    title: 'Call Of Duty (mobile)',
    description: 'Call Of Duty | gameplay on mobile morning city war',
    iframe: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/DI3wE84OQGI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />,
  },
  {
    title: 'Gangstar Vegas (mobile)',
    description: 'Gangstar Vegas | Gameplay',
    iframe: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/wXft6mThO4s" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />,
  },
  {
    title: 'Sniper Ghost Warrior 2',
    description: 'Sniper Ghost Warrior 2 | Evening Mission: How to complete it',
    iframe: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/3jv1hVYB79g" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />,
  },
  {
    title: 'Formula 1',
    description: 'Formula 1 (F1) | Did I won the race with my CUSTOM F1?',
    iframe: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/BpGb4dfEx18" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />,
  },
  {
    title: 'PUBG',
    description: 'PUBG | Playing PUBG after long time... loved it!',
    iframe: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/KU5voWVzHcE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />,
  },
  {
    title: 'Sniper Ghost Warrior 2',
    description: 'Sniper ghost warrior 2 | Stealth kill mission gameplay',
    iframe: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/09cW_cgoa_Y" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />,
  },
  {
    title: 'MudRunner',
    description: 'MudRunner | Russian UAZ legendary off-road SUV gameplay',
    iframe: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/I7tfgFa9KGI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />,
  },
  {
    title: 'Vector',
    description: 'Vector | Downtown parkour escape - Catch me if you can!',
    iframe: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/py7Zolde61k" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />,
  },
  {
    title: 'MudRunner',
    description: 'MudRunner | American car blazer suv off road',
    iframe: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/qIdWEIYJ-O4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />,
  },
  {
    title: 'Max Payne 2',
    description: 'Max Payne 2 | Death mission!',
    iframe: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/HU34arPUtC0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />,
  },
  {
    title: 'MudRunner',
    description: 'MudRunner | Truck off-road wood delivery',
    iframe: <iframe width="100%" height="100%" src="https://www.youtube.com/embed/oZUjSvhnckg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />,
  },
];
