/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Divider, Layout } from 'antd';
// import Lottie from 'lottie-react';
import styled from 'styled-components';
import { Header } from '../../components';
import { videos } from './videos';
import { VideoCard } from './VideoCard';
import { colors } from '../../constants';
import { SubscriberForm } from './SubscriberForm';

// import goLive from './go-live.json';
// import { liveVideos } from './liveVideos';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
`;

const Footer = styled.div`
  height: 80px;
  background-color: #00a4c7;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

const {
  Content,
} = Layout;

function HomePage() {
  // const renderLiveVideos = () => liveVideos.map((video, index) => (
  //   <div key={`${video.title}-${index}`}>
  //     <VideoCard
  //       title={video.title}
  //       description={video.description}
  //       iframe={video.iframe}
  //     />
  //   </div>
  // ));

  const renderVideos = () => videos.map((video, index) => (
    <div key={`${video.title}-${index}`}>
      <VideoCard
        title={video.title}
        description={video.description}
        iframe={video.iframe}
      />
    </div>
  ));

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: 'white' }}>
      <Header />
      <Content style={{ padding: '0 24px' }}>
        {/* <Divider style={{
          fontSize: 25,
          fontWeight: 400,
          color: colors.darkBlue,
          marginTop: 50,
        }}
        >
          <Lottie animationData={goLive} loop />
        </Divider> */}
        {/* <Container>
          {renderLiveVideos()}
        </Container> */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <SubscriberForm />
        </div>
        <Divider style={{
          fontSize: 25,
          fontWeight: 400,
          color: colors.darkBlue,
          marginTop: 50,
        }}
        >
          Highlighted Videos
        </Divider>
        <Container>
          {renderVideos()}
        </Container>
      </Content>
      <Footer>
        <span style={{ textAlign: 'center', color: colors.white }}>©2022 Created by GAMEINO</span>
      </Footer>
    </Layout>
  );
}

export { HomePage };
