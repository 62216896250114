import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import { HomePage } from '../pages';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
]);

function Routes() {
  return (
    <RouterProvider router={router} />
  );
}

export { Routes };
