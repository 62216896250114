import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import Lottie from 'lottie-react';
import { GameinoLogo } from './gameinoLogo';
import { YoutubeLogo } from './youtubeLogo';
import { breakpoints, colors, gameinoChannelLink } from '../constants';
import welcomeAnimation from './welcome.json';

const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    // background-color: ${colors.lightBlue};
    padding: 20px 50px;
    @media (max-width: ${breakpoints.small}) {
        flex-direction: column;
        justify-content: space-around;
        min-height: 260px;
        padding: 0px;
    }
`;

const SubscribeButton = styled(Button)`
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
`;

function Header() {
  const openYoutube = () => {
    window.open(gameinoChannelLink, '_blank');
  };

  return (
    <Container>
      <div style={{
        width: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <GameinoLogo style={{ width: 80 }} />
      </div>
      <div style={{ maxWidth: 300 }}>
        <Lottie animationData={welcomeAnimation} loop={false} />
      </div>
      <SubscribeButton
        type="primary"
        onClick={openYoutube}
      >
        <YoutubeLogo style={{ width: 20, marginRight: 5 }} />
        View channel
      </SubscribeButton>
    </Container>
  );
}

export { Header };
